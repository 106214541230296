import { useEffect } from 'react'
import 'styles/globals.scss'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import AOS from 'aos';
import { CacheTime } from 'constants/cacheTime';
import "aos/dist/aos.css";

const queryConfig = {
  refetchOnWindowFocus: false,
  retry: 2,
  cacheTime: CacheTime.FiveMinutes,
};



function MyApp({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: queryConfig,
    },
  });

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html')!.style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html')!.style.scrollBehavior = ''
  }, []); // triggered on route change


  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
