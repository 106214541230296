export const ONE_MINUTE = 60000;

export enum CacheTime {
    OneMinute = ONE_MINUTE,
    FiveMinutes = 5 * ONE_MINUTE,
    FifteenMinutes = 15 * ONE_MINUTE,
    ThirtyMinues = 30 * ONE_MINUTE,
    OneHour = 60 * ONE_MINUTE,
    Forever = Infinity,
}
